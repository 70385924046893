/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import "@hotwired/turbo-rails"

import { Application } from "@hotwired/stimulus"
import { registerControllers } from 'stimulus-vite-helpers'

window.Stimulus = Application.start()
const controllers = import.meta.glob('../controllers/*_controller.js', { eager: true })
registerControllers(Stimulus, controllers)

// Import and register TailwindCSS Components
//
// These are different, yet overlapping, with Stimulus Tailwind Components imported below.
//
// https://github.com/excid3/tailwindcss-stimulus-components
import { Alert, Autosave, Dropdown } from "tailwindcss-stimulus-components"
Stimulus.register('alert', Alert)
Stimulus.register('autosave', Autosave)
Stimulus.register('dropdown', Dropdown)

// Import and register Stimulus Tailwind Components
//
// These are different, yet overlapping, with TailwindCSS Stimulus components
// imported above.
//
// https://stimulus-tailwind.chiefpansancolt.dev
import { Switch } from "stimulus-tailwind-components"
Stimulus.register('switch', Switch)

import NestedForm from 'stimulus-rails-nested-form'
Stimulus.register('nested-form', NestedForm)

import Remote from 'stimulus-remote-rails'
Stimulus.register('remote', Remote)

import { StreamActions } from "@hotwired/turbo"
StreamActions.event = function () {
  const eventName = this.templateContent.textContent
  if (eventName) {
    const backendEvent = new Event(eventName)
    document.dispatchEvent(backendEvent)
  }
}
